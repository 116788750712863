body {
    -webkit-print-color-adjust: exact !important;
}

.content-wrapper {
    overflow-y: scroll;
    overflow-x: hidden;
    outline: 3px solid blueviolet;
    width: 99%;
    margin: auto;
}

.clickble {
    cursor: pointer;
}

.date-picker-wrapper {
    position: absolute !important;
}

@media print {
    body * {
        visibility: hidden;
    }

    #section-to-print,
    #section-to-print * {
        visibility: visible;
    }

    #section-to-print {
        position: absolute;
        left: 0;
        top: 0;
    }
}

.absolute-overlay {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 500000;
}

.absolute-overlay-body {
    background: white;
    height: 60%;
    width: 50%;
    margin: auto;
    margin-top: 10%;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
}

.react-datepicker-popper {
    z-index: 5;
}

.react-datepicker-wrapper {
    width: 100%;
}

.materialui-daterange-picker-makeStyles-dateRangePicker-2 {
    z-index: 5555 !important;
}

a{
    text-decoration: none;
}
a:hover{
    text-decoration: none;
}