/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
  background-color: rgba(200, 200, 200, 0);
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(200, 200, 200, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #1f8867; /* Chnage this property for background */
  border: 1px solid grey;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: grey;
  border: 1px solid white;
}

body {
  scrollbar-color: grey rgba(200, 200, 200, 0);
  scrollbar-width: 5px;
  scrollbar-highlight-color: black;
}

html,
body {
  scrollbar-track-color: white;
  -moz-scrollbar-track-color: white;
  scrollbar-face-color: silver;
  -moz-scrollbar-face-color: silver;
  scrollbar-arrow-color: black;
  -moz-scrollbar-arrow-color: black;
}

@media screen and (max-width: 480px) {
  /* width */
  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    background-color: rgba(200, 200, 200, 0);
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(200, 200, 200, 0);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    --webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    background-color: var(--bg-dark);
    border: 1px solid grey;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: grey;
    border: 1px solid white;
  }

  body {
    scrollbar-color: grey rgba(200, 200, 200, 0);
    scrollbar-width: 1px;
    scrollbar-highlight-color: black;
  }

  html,
  body {
    scrollbar-track-color: white;
    -moz-scrollbar-track-color: white;
    scrollbar-face-color: silver;
    -moz-scrollbar-face-color: silver;
    scrollbar-arrow-color: black;
    -moz-scrollbar-arrow-color: black;
  }
}
